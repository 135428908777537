body {
    font-family: 'Poppins', sans-serif !important;
}


h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 140%;
    margin-bottom: 15px;
    color: #0E2C57;
}

p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 175%;
    text-align: left;
    color: #282829;
    &.large {
        font-size: 18px;
    }
    &.medium {
        font-size: 16px;
    }
}

.navigation {
    background: #fff;
    border-bottom: solid 1px #eee;
    &__inner {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 30px;
        display: flex;
        .Brand {
            flex: 1;
        }
        .right__menu {
            a {
                text-decoration: none;
                color: #333;
                font-size: 14px;
                margin-left: 20px;
                font-weight: 800;
                opacity: 0.7;
                transition: all 0.3s;
                &:hover {
                    opacity: 1;
                    transition: all 0.3s;
                }
            }
            a.buttonNavigation {
                border: solid 2px #388BDD;
                padding: 7px 20px;
                border-radius: 60px;
                color: #388BDD !important;
                transition: all 0.3s;
                &:hover {
                    background-color: #388BDD;
                    color: #fff !important;
                    transition: all 0.3s;
                    box-shadow: 0px 20px 40px #2196f366;
                }
            }
        }
    }
}


.banner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10vw;
    background: rgb(52,121,191);
    background: linear-gradient(180deg, rgba(52,121,191,1) 0%, rgba(26,59,107,1) 100%);
    .content {
        flex: 1;
        width: 100%;
        p {
            font-size: 16px;
            line-height: 22px;
            opacity: 0.7;
            color: #fff;
            line-height: 24px;
        }
        h3 {
            font-size: 48px;
            font-weight: 600;
            margin-bottom: 16px;
            color: #fff;
        }
    }
    .bannerImage {
        flex: 1;
        text-align: center;
        img {
            width: 100%;
            max-width: 450px
        }
    }
}


.sectionBox {
    display: flex;
    background: #F5FAFF;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 120px 0px;
    .sectionItems {
        display: flex;
        align-items: center;
        h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 175%;
            color: #000000;
        }
        p {
            font-size: 14px;
            line-height: 22px;
            opacity: 0.6;
            margin-right: 30px;
            margin-left: 0px !important;
        }
        img {
            height: 180px;
            margin-right: 26px;
        }
    }
}

a.primary.button svg {
    margin-left: 10px;
    width: 18px;
}

a.primary.button {
    display: flex !important;
    width: fit-content;
    align-items: center;
}

a.primary.button.light {
    background: #509DE9;
}

a.primary.button {
    background: #1a3c6c;
    color: #fff;
    text-decoration: none;
    padding: 10px 25px;
    display: inline-block;
    margin: 10px 0px 0px;
    font-size: 16px;
    border-radius: 30px;
}


.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 120px 0px;
}

.smallContainer {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 120px 0px;
}
.navigation {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
}

.mainBackground {
    padding-top: 77px;
}


.grid {
    display: flex;
    align-items: center;
    .box {
        flex: 1;
    }
}

img.f-w {
    width: 100%;
    margin-right: 30px;
    flex: 1;
}

.sectionAbout {
    background: #F5FAFF;
    border-bottom-left-radius: 200px;
    b {
        font-weight: bold;
        color: #1a3b6c;
    }
    p {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 20px;
        color: #333;
        margin-left: 40px;
    }
}

.sectionclean{
    b {
        font-weight: bold;
        color: #1a3b6c;
    }
    p {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 20px;
        color: #333;
        margin-left: 40px;
    }
}


.sectionTitle {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #1a3b6c;
    margin-bottom: 30px;
}

.box.tujuanItem {
    display: flex;
    align-items: center;
    img {
        width: 100%;
        max-width: 54px;
        margin-right: 20px;
    }
    h5 {
        font-size: 14px;
        line-height: 22px;
        display: inline-block;
        width: 100%;
        margin-right: 20px;
    }
}


.no-padding {
    padding: 0px 0px 120px;
}

.sectionclean.blueBg {
    background: #1A3B6B;
}

.card {
    background: #fff;
    width: 33.3%;
    padding: 30px;
    height: 315px;
    margin: 10px;
    border-radius: 10px;
    transition: all 0.3s;
    &:hover {
        box-shadow: 0px 30px 70px #0000005c;
        transform: scale(1.03);
        transition: all 0.3s;
    }
    img {
        width: 90px;
        margin: auto;
        margin-top: 0;
        margin-bottom: 5px;
    }
    p {
        margin: 0px;
        font-size: 12px;
    }
}


.col-small {
    width: 30%;
    padding: 0px 20px;
    margin-right: 30px;
}

.col-large {
    width: 70%;
}


.leftTitle {
    text-align: center;
    h2 {
        color: #fff;
        font-size: 36px;
        margin-top: 20px;
    }
    img {
        width: 100%;
    }
}

.sectionCta {
    background-size: 84%;
    background-position: center center;
    background-repeat: no-repeat;
    .cta {
        display: flex;
        align-items: center;
        .content {
            flex: 1;
            width: 50%;
        }
        .imgBanner {
            flex: 1;
            margin-left: 70px;
            img {
                width: 100%;
            }
        }
    }
}


.mainBackground {
    background-size: cover !important;
    background-position: 20px 900px;
    background-repeat: no-repeat;
}

.footer {
    background: #3479BF;
    padding: 60px 60px 20px;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
    .footerMid {
        display: flex;
        .download {
            width: 300px;
            img {
                width: 100%;
                max-width: 180px;
                margin-bottom: 10px;
            }
            h4 {
                color: #fff;
                font-weight: 500;
                margin-bottom: 15px;
            }
        }
        .link {
            width: 170px;
            margin-right: 60px;
            a {
                display: block;
                color: #fff;
                text-decoration: none !important;
                font-size: 14px;
                font-weight: unset;
                margin-bottom: 15px;
            }
        }
    }
    .info {
        width: 450px;
        flex: 1;
        margin-right: 60px;
        .infoItem {
            display: flex;
            align-items: flex-start;
            margin-bottom: 20px;
            img {
                margin-right: 15px;
                margin-top: -2px;
            }
            p {
                color: #fff;
                font-size: 14px;
                text-align: left;
            }
        }
    }
}


.footerBottom {
    display: flex;
    color: #fff;
    border-top: solid 1px;
    padding-top: 20px;
    margin-top: 50px;
    p {
        color: #fff;
        flex: 1;
        font-size: 12px;
    }
    .linkSocial {
        display: flex;
        align-items: center;
        img {
            height: 21px;
            margin-left: 15px;
        }
    }
}


.footerTop {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    img {
        width: 100%;
        max-width: 250px;
        margin-bottom: 50px;
    }
}

.Brand img {
    width: 100%;
    max-width: 130px;
    position: absolute;
    top: 10px;
}

.container {
    display: flex;
    align-items: center;
}

.featureGrid {
    display: flex;
    margin-top: 60px !important;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    .featureItem {
        margin: 0px 15px;
        display: flex;
        align-items: flex-start;
        width: 33.3%;
        img {
            margin-right: 21px;
        }
        h3 {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 10px;
            font-weight: 500;
        }
        p {
            text-align: left;
            font-size: 12px;
        }
    }
}

.features {
    padding: 120px 0px;
    .container {
        padding: 0px !important;
    }
    .image {
        width: 35%;
        margin-right: 30px;
        img {
            width: 100%;
        }
    }
    .content {
        width: 75%;
    }
}

.companyBanner {
    background-size: 100% !important;
    background-position: bottom !important;
    .bannerInner {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        .images {
            width: 50%;
            flex: 1;
            padding-top: 140px;
            img {
                width: 100%;
                max-width: 550px;
            }
        }
        .content {
            width: 50%;
            a {
                text-decoration: none !important;
                display: inline-block;
                background: #388BDD;
                border: none;
                padding: 15px 37px;
                font-size: 16px;
                color: #fff;
                border-radius: 50px;
            }
            h1 {
                color: #fff;
                font-size: 42px;
            }
            p {
                color: #fff;
                text-align: left;
                font-size: 16px;
                opacity: 0.7;
                margin-bottom: 30px;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .bannerPage img {
        display: none;
    }
    .bannerPage {
        padding: 110px 25px 40px !important;
        flex-direction: row;
    }
    .content {
        padding: 0px 30px;
    }
    .phoneAssets {
        max-width: 250px !important;
    }
    .container.rightContent {
        flex-direction: column-reverse !important;
    }
    .gridContent .contentBox {
        width: 100% !important;
    }
    .largeTitle {
        flex-direction: column-reverse;
    }
    .largeTitle h2 {
        color: #fff;
        font-size: 28px !important;
        line-height: 38px !important;
        font-weight: 500;
        flex: 1 1;
        margin: 20px 0px;
    }
    .benefitworker .container {
        flex-direction: column;
    }
    .contentTitle {
        width: 100% !important;
        flex: 1 1;
    }
    .contentTitle img {
        width: 100%;
        display: none !important;
    }
    .contentTitle h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 31px !important;
        line-height: 37px !important;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        text-transform: capitalize;
        color: #212121;
        margin-bottom: 30px;
    }
    .contentBenefit {
        flex: 1 1;
        width: 100% !important;
        padding-left: 0px !important;
    }
    .ctaAssets {
        display: none !important;
    }
    .ctaContent {
        flex: 1 1;
        margin-left: 0px;
    }
    .ctaBox {
        padding: 20px 0px 40px;
    }
    
    .benefitworker {
        padding: 0px 25px;
    }
    .assetsTitle {
        display: none !important;
    }
    .largeTitle {
        padding: 0px 25px;
    }
    .testimoni {
        padding: 60px 0px !important;
    }    
    .gridContent {
        padding: 0px 25px;
    }
    .titleSection h1 {
        font-size: 32px !important;
    }
    .halfBanner h1 {
        font-size: 21px !important;
        margin-top: 60px !important;
    }
    .gridContent .assetsBox {
        width: 100% !important;
        margin-left: 0px !important;
        flex: 1 1;
    }
    .gridContent .contentBox h2 {
        font-style: normal;
        font-size: 23px !important;
        margin-top: 20px !important;
    }
    .contentBox.smallWidth {
        width: 100% !important;
        margin: 0px !important;
    }
    .gridContent .container {
        flex-direction: column;
    }
    .companyBanner {
        margin-top: 72px;
        background-size: cover !important;
        padding: 60px 25px;
        .bannerInner {
            flex-direction: column-reverse;
            .images {
                display: none !important;
            }
            .content {
                width: 100% !important;
            }
        }
    }

    .testimoni {
        padding: 120px 0px;
        width: 100vw;
        overflow-x: hidden;
    }

    .features .container {
        padding: 0px 0px !important;
        flex-direction: column;
    }
    .features .content {
        width: 100%;
    }

    .features {
        padding: 60px 25px;
    }

    .featureGrid {
        flex-direction: column;
    }

    .featureItem {
        width: 100% !important;
        margin: 0px 0px 25px !important;
    }

    .klienGrid a {
        width: 50% !important;
    }

    .klienGrid a img {
        height: auto !important;
        width: 60%;
    }
    .benefit .assets {
        display: none !important;
    }

    .benefit .content {
        padding: 60px 25px;
    }
    .benefitCard {
        flex-direction: column;
        margin: 0px !important;
    }
    .benefitItem {
        width: calc(100% - 40px) !important;
        margin: 10px 0px !important;
    }
    .benefit .content h2 {
        color: #fff;
        font-size: 34px;
        font-weight: 600;
        line-height: 42px;
        margin-bottom: 15px;
    }
    body {
        width: 100vw;
        overflow-x: hidden;
    }


    .banner {
        flex-direction: column-reverse;
        margin-top: 70px;
    }
    .bannerImage {
        margin-bottom: 30px;
    }
    .banner .content h3 {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 16px;
        color: #fff;
        line-height: 36px;
    }
    .banner .content p {
        font-size: 14px;
        line-height: 22px;
        opacity: 0.7;
        color: #fff;
        line-height: 22px;
        margin-bottom: 60px;
    }
    .sectionBox {
        flex-direction: column;
    }
    .sectionBox .sectionItems {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0px 35px;
        margin-bottom: 60px;
    }
    .sectionBox .sectionItems img {
        height: 180px;
        margin-right: 26px;
        margin-bottom: 10px;
    }
    .sectionBox {
        padding: 60px 0px;
    }
    .container {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 60px 0px;
    }
    .grid {
        flex-direction: column;
        padding: 0px 30px;
    }
    .sectionAbout p {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 20px;
        color: #333;
        margin-left: 0px;
    }
    .sectionclean p {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 20px;
        color: #333;
        margin-left: 0px;
    }
    .box.tujuanItem {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 24px;
    }
    .container.no-padding br {
        display: none;
    }
    .col-large {
        width: 100%;
    }
    .col-small {
        width: 100%;
        margin-bottom: 30px;
        margin-right:0px !important;
    }
    .leftTitle img {
        width: 100%;
        max-width: 220px;
    }
    .card {
        width: 100%;
        transition: all 0.3s;
    }
    .imgBanner {
        margin-left: 0px !important;
        padding: 0px 45px;
        margin-bottom: 40px;
    }
    .sectionCta .cta .content {
        flex: 1 1;
        width: 100% !important;
    }
    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 140%;
        margin-bottom: 15px;
        color: #0E2C57;
    }
    p.large {
        font-size: 16px;
    }
    .footer {
        background: #3479BF;
        padding: 61px 30px 20px;
        border-top-left-radius: 60px;
        border-top-right-radius: 60px;
    }
    .footerMid {
        flex-direction: column;
    }
    .footer .info {
        width: 100%;
        flex: 1 1;
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .download {
        margin-top: 40px;
    }
    .linkSocial {
        margin-top: 30px;
    }
    .right__menu a {
        display: block;
        margin-bottom: 15px;
    }
    .right__menu.showMenu {
        display: block !important;
    }
    .right__menu {
        display: none;
        padding-top: 20px;
        background: #fff;
        position: absolute;
        z-index: 1000;
        height: auto;
        width: 100%;
        right: 0;
        top: 60px;
        overflow: hidden;
        border-top: solid 1px #0000001a;
    }
    .navigation__inner {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 8px 0;
        display: flex;
    }
    .Brand {
        margin-left: 20px;
    }
    .mobMenu {
        display: block !important;
        padding: 14px; 
        transform: scale(0.7);
    }
    .navigation {
        position: fixed;
        width: 100%;
        z-index: 1000;
        top: 0;
    }
    .Brand img {
        width: 100%;
        max-width: 100px;
    }
    a.buttonNavigation {
        display: inline-block;
    }
    
    .footerBottom {
        flex-direction: column;
    }
    .cta {
        flex-direction: column-reverse;
        padding: 0px 25px;
    }    
    .sectionBox .sectionItems p {
        font-size: 14px;
        line-height: 22px;
        opacity: 0.6;
        margin-right: 30px;
        margin-left: 0px !important;
    }
}

.mobMenu {
    display: none;
}

.benefit {
    background: #193968;
    .container {
        padding: 0px !important;
    }
    .assets {
        max-width: 380px;
        margin-right: 70px;
        width: 100%;
        padding-top: 120px;
        img {
            width: 100%;
        }
    }
    .content {
        h2 {
            color: #fff;
            font-size: 34px;
            font-weight: 600;
            margin-bottom: 30px;
        }
        .benefitCard {
            display: flex;
            margin: 0px -10px;
            .benefitItem {
                margin: 10px 10px;
                background: #fff;
                padding: 20px;
                border-radius: 10px;
                flex: 1;
                width: 50%;
                transition: all 0.3s;
                &:hover {
                    box-shadow: 0px 30px 70px #0000005c;
                    transform: scale(1.03);
                    transition: all 0.3s;
                }
                img {
                    margin-bottom: 10px;
                }
                h5 {
                    line-height: 24px;
                }
            }
        }
        
    }
}


.testimoni {
    padding: 120px 0px;
    &__inner {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        h3 {
            text-align: center;
            font-size: 34px;
            font-weight: 600;
            margin-bottom: 60px;
            color: #0E2C57;
        }
    }
}

.cardTestimoni {
    text-align: center;
    padding: 20px 30px;
    h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        text-align: center;
        letter-spacing: 0.2px;
        text-transform: capitalize;
        color: #424242;
        margin-bottom: 25px !important;
        display: block;
        max-width: 390px;
        margin: 0 auto;
    }
    img {
        width: 100%;
        max-width: 32px;
        margin: 0 auto;
    }
    img.stars {
        display: inline-block;
        max-width: 24px;
        margin-bottom: 15px;
    }
    p {
        text-align: center;
        margin-top: 6px;
    }
}

.klienGrid {
    display: flex;
    flex-wrap: wrap;
    a {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 0px;
        transition: all 0.3s;
        filter: grayscale(1);
        opacity: 0.6;
        img {
            height: 70px;
        }
        &:hover {
            opacity: 1;
            transition: all 0.3s;
            filter: grayscale(0);
        }
    }
}

.listKlien {
    margin-top: 120px;
}


.halfBanner h1 {
    color: #fff;
    font-size: 42px;
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
}

.halfBanner {
    background: linear-gradient(360deg, #1A3B6B -9.77%, #3479BF 94.92%);
    text-align: center;
    display: flex;
    height: 400px;
    align-items: flex-start;
    padding-top: 80px;
}

.bottomBanner {
    text-align: center;
}

.bottomBanner img {
    height: 65px;
    margin: 0px 5px;
}

.container.no-padding {
    display: block;
}

.bottomBanner h4 {
    font-size: 24px;
    margin: 60px 0px 15px;
}

.phoneAssets {
    height: auto !important;
    width: 100%;
    max-width: 500px;
    margin-top: -210px !important;
}

.bottomBanner {
    background: #F5FAFF;
    padding-bottom: 120px;
}

.gridContent {
    .contentBox {
        width: 50%;
        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 42px;
            line-height: 140%;
            margin-bottom: 15px;
            color: #0E2C57;
        }
    }
    .assetsBox {
        width: 50%;
        margin-left: 60px;
        flex: 1;
        img {
            width: 100%;
        }
    }
}

.titleSection h1 {
    font-size: 42px;
}

.titleSection {
    margin: 60px 0px;
    text-align: center;
}

.smallWidth {
    width: 40% !important;
    margin-left: 70px;
}

.centerText {
    text-align: center;
    width: 100% !important;
}

.testimoni.darkBg {
    background: #0E2C57;
}

.assetsBox.smallBox {
    display: flex;
    justify-content: center;
}
.assetsBox.smallBox img {
    width: 100%;
    max-width: 240px;
}

.leftBox {
    justify-content: center !important;
}

.largeTitle img {
    width: 190px;
}

.largeTitle {
    display: flex;
    margin-bottom: 30px;
}
.largeTitle h2 {
    color: #fff;
    font-size: 58px;
    line-height: 66px;
    font-weight: 500;
    flex: 1;
    margin: 0px;
}

.cardTestimoni.DarkTestimoni {
    background: #fff;
    border-radius: 10px;
    margin: 0px 15px;
}

.topTestimoni {
    display: flex;
}
.profileInfo {
    flex: 1;
}
.testiUser img {
    width: 46px;
    height: 46px;
    flex: none;
    max-width: unset;
}
.testiUser {
    flex: 1;
    display: flex;
}
.profileInfo span {
    font-size: 14px;
    opacity: 0.5;
}
.profileInfo p {
    margin: 0px;
    text-align: left;
}
.profileInfo {
    flex: 1 1;
    text-align: left;
    padding-left: 15px;
}

.cardTestimoni.DarkTestimoni h4 {
    text-align: left;
    padding: 0px;
    width: 100%;
    max-width: fit-content;
    font-size: 14px;
    line-height: 23px;
    margin-top: 21px;
    margin-bottom: 0px !important;
}


.contentTitle {
    width: 50%;
    flex: 1;
    h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 44px;
        line-height: 57px;
        display: flex;
        align-items: center;
        letter-spacing: 0.2px;
        text-transform: capitalize;
        color: #212121;
        margin-bottom: 30px;
    }
    img {
        width: 100%;
    }
}

.contentBenefit {
    flex: 1;
    width: 50%;
    padding-left: 120px;
}
.contentBenefit__item p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-align: left;
    line-height: 145%;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    color: #212121;
    flex: 1;
}
.contentBenefit__item span {
    display: block;
    width: 30px;
    height: 30px;
    background: #EFF3FD;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2851E3;
    margin-right: 10px;
}
.contentBenefit__item {
    display: flex;
    margin-bottom: 24px;
}


.ctaContent {
    flex: 1;
    margin-left: 10%;
}

.ctaBox {
    display: flex;
    align-items: center;
}
.ctaAssets {
    width: 100%;
    max-width: 470px;
    img {
        width: 100%;
    }
}
.downloadApp {
    margin-top: 15px;
}

.downloadApp img {
    height: 60px;
    margin-right: 10px;
}
.ctaContent h4 {
    margin-top: 40px;
    font-weight: 600;
    margin-bottom: 20px;
}

.content_container strong {
    font-weight: 800;
    margin-right: 10px;
}

.content_container li {
    margin-bottom: 20px;
    line-height: 24px;
}

.content_container p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
}
.content_container {
    font-size: 14px;
    line-height: 24px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.content_container ul {
    margin-left: 30px;
}

.content_container h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
}

.bannerPage h1 {
    color: #fff;
}

.bannerPage img {
    width: 100%;
    max-width: 300px;
    margin-right: 60px;
}
.bannerPage {
    background: linear-gradient(360deg, #1A3B6B -9.77%, #3479BF 94.92%);
    align-items: center;
    display: flex;
    padding-left: 120px;
    margin-bottom: 60px;
}

.content_container.ol li {
    list-style: disc;
}