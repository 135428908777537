html, body {
    height: 100%;
  }

.bg{
    background: rgb(18,39,68);
    background: linear-gradient(124deg, rgba(18,39,68,1) 0%, rgba(0,212,255,1) 100%);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
}
.headLink{
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.button{
    border-color: transparent;
    box-shadow: none !important;
    text-transform: capitalize;
    width: 250px;
    font-size: 15px;
    font-weight: 600;
    color: #fff !important;
    background-color: #122744 !important;
    border-radius: 20px;
    box-shadow: 0px 2px 20px 2px #d4d4d43d !important;
    margin-top: 20px;
}
.button:hover{
    border-color: transparent;
    box-shadow: none !important;
}