.containerVacancy{
    width: 100%;
    padding: 0 10vw;
    margin-top: 100px;
    margin-bottom: 100px;
    .searchBox{
        padding: 40px 40px;
        border-radius: 6px;
        border: solid 1px #FFFFFF;
        box-shadow: 0px 10px 31px rgb(0 0 0 / 12%);
        margin-bottom: 100px;
        .text{
            margin-bottom: 20px;
            h4{
                font-size: 18px;
                font-weight: 600;
            }
        }
        .innerBox{
            display: flex;
            .inputPage{
                width: 85%;  
                input{
                    padding: 14px 16px;
                    border-radius: 6px;
                    border: solid 1px #388BDD;
                    width: 100%;
                    box-sizing: border-box;
                    color: #A3A4A5;
                }
                img{
                    position: absolute;
                    margin-left: -45px;
                    margin-top: 15px;
                }
            }
            button{
                padding: 14px 16px;
                margin-left: 55px;
                border-radius: 6px;
                border: solid 1px #388BDD;
                width: 15%;
                box-sizing: border-box;
                background-color: #388BDD;
                color: #FFFFFF;
            }
        } 
    }
    .contentVacancy{
        h2{
            font-size: 38px;
            font-weight: bold;
        }
        .cardBox{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 100px;
            .cardVacancy { 
                background: #FFFFFF;
                width: 350px;
                height: 350px;
                margin: 30px;
                border-radius: 10px;
                box-shadow: 0px 10px 31px rgb(0 0 0 / 12%);
                transition: all 0.3s;
                &:hover {
                    box-shadow: 0px 10px 20px #0000005c;
                    transform: scale(1.03);
                    transition: all 0.3s;
                }
                img {
                    width: 100%;
                    height: 55%;
                    margin-bottom: 10px;
                    border-top-right-radius: 10px;
                    border-top-left-radius: 10px;
                    object-fit: cover !important;
                }
                .text{
                    margin: 5px 5px 5px 15px;
                }
                .location{
                    width: 100%;
                    display: flex;
                    img{
                        width: 7%;
                    }
                    .text{
                        margin-left: 30px;
                        position: absolute;
                    }
                }
                button{
                    margin-top: 13px;
                    padding: 5px 15px 5px 15px;
                    background-color: #388BDD;
                    color: #FFFFFF;
                    font-size: 10px;
                    border-radius: 10px;
                    border: none;
                    
                }
                h3{
                    font-size: 18px;
                    font-weight: 600;
                }
                h4{
                    font-size: 14px;
                    color: #7E7F80;
                }
                p {
                    margin: 0px;
                    font-size: 12px;
                }
            }
        }
    }
}